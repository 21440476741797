import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import useSearch from "./useSearch";
import React, { useEffect } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "../MenuItem";
import dayjs from "dayjs";

const SearchArticleForm = (props) => {
  const validateForm = () => {
    const errors = {};

    if (props.inputs.title != null && props.inputs.title.length > 50) {
      errors.title = "Title is too long";
    }

    props.setInputs((prevState) => ({ ...prevState, errors }));

    return Object.keys(errors).length === 0;
  };

  const searchArticles = (event) => {
    event.preventDefault();

    const isValid = validateForm();

    if (isValid) {
      props.searchArticles(event);
      props.handleClose();
    }
  };
  const resetForm = () => {
    props.resetForm();
    props.handleClose();
  };

  const handleChangeCheckbox = (event) => {
    const name = event.target.name;
    const value = event.target.checked;

    props.setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeDate = (value, name) => {
    props.setInputs((values) => ({ ...values, [name]: value }));
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box component="form" onSubmit={searchArticles} sx={{ mt: 1 }}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <TextField fullWidth label="Title" name="title" value={props.inputs.title} onChange={props.handleChange} variant="standard" />
              {props.inputs.errors.title && <p style={{ color: "red" }}>{props.inputs.errors.title}</p>}
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{ textField: { variant: "standard", fullWidth: true } }}
                  label="Date from"
                  name="dateFrom"
                  value={props.inputs.dateFrom}
                  onChange={(value) => handleChangeDate(value, "dateFrom")}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{ textField: { variant: "standard", fullWidth: true } }}
                  label="Date to"
                  name="dateTo"
                  value={props.inputs.dateTo}
                  onChange={(value) => handleChangeDate(value, "dateTo")}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox name="isDescending" checked={props.inputs.isDescending} onChange={handleChangeCheckbox} />}
                  label="Older articles first"
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mb: 1 }}>
            <Button type="submit" fullWidth variant="contained" sx={{ borderRadius: "0px" }}>
              Search
            </Button>
          </Grid>

          <Grid item xs={12} sx={{ mb: 1 }}>
            <Button onClick={resetForm} fullWidth variant="outlined" sx={{ borderRadius: "0px", backgroundColor: "gainsboro" }}>
              Reset
            </Button>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

const SearchDialog = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MenuItem onClick={handleClickOpen}>Search</MenuItem>

      <Dialog open={open} onClose={handleClose} BackdropProps={{ invisible: true }} PaperProps={{ sx: { borderRadius: "0px" } }}>
        <DialogTitle sx={{ textAlign: "center" }}>{/* {"\u269C"} Search {"\u269C"}{" "} */}~ Search ~</DialogTitle>
        <DialogContent>
          <SearchArticleForm
            searchArticles={props.searchArticles}
            handleChange={props.handleChange}
            inputs={props.inputs}
            setInputs={props.setInputs}
            handleClose={handleClose}
            resetForm={props.resetForm}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SearchDialog;
