import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography } from "@mui/material";
import MenuItem from "./MenuItem";

export default function AboutDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MenuItem onClick={handleClickOpen}>About</MenuItem>

      <Dialog open={open} onClose={handleClose} BackdropProps={{ invisible: true }} PaperProps={{ sx: { borderRadius: "0px" } }}>
        <DialogTitle sx={{ textAlign: "center" }}>{/* {"\u269C"} About {"\u269C"}{" "} */}~ About ~</DialogTitle>
        <DialogContent>
          <p>
            'The Comfort Devices' is a blog, with central emphasis not on text, but on images. Most of them are simple quick-sketches of my own
            authorship, digital and non-digital alike, created either in times of inspiration or mere circumstances and thrown down on paper for
            convenience sake.
          </p>
          <p>
            Some posts may be attempts in retrospective, a caricature-like testimonies of daily emotional struggles, a poorly handled jokes or nothing
            but scribbles, incomprehensible even to its author. Each unique visual “art” presented with each post may be open to interpretation.
          </p>
        </DialogContent>
      </Dialog>
    </>
  );
}
