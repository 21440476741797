import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import useSearch from "./search/useSearch";
import { Button, Fade, IconButton, Pagination, Typography } from "@mui/material";
import SearchDialog from "./search/SearchDialog";
import AboutDialog from "./AboutDialog";
import parse from "html-react-parser";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import MenuItem from "./MenuItem";
import { SiteConsts } from "./SiteConsts";
import { useEffect } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

const Logo = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", textAlign: "center", borderWidth: "0px 0px 2px 0px", borderStyle: "solid" }}>
      <Typography sx={{ fontSize: "70px", fontFamily: SiteConsts.FONT_KEY_GLOBAL_1 }}>The Comfort Devices</Typography>
    </Box>
  );
};

const Sound = () => {
  const [isSoundOn, setIsSoundOn] = React.useState(false);
  const [beat, setBeat] = React.useState(null);

  useEffect(() => {
    let beat = new Audio("https://static.blog.alvrineom.com/blog bgm.mp3");
    beat.loop = true;
    setBeat(beat);
  }, []);

  useEffect(() => {
    if (isSoundOn) {
      try {
        beat?.play();
      } catch (error) {}
    } else {
      beat?.pause();
    }
  }, [isSoundOn]);

  if (isSoundOn) {
    return (
      <IconButton onClick={() => setIsSoundOn(false)} sx={{ color: "black" }}>
        <VolumeUpIcon></VolumeUpIcon>
      </IconButton>
    );
  } else {
    return (
      <IconButton onClick={() => setIsSoundOn(true)} sx={{ color: "black" }}>
        <VolumeOffIcon></VolumeOffIcon>
      </IconButton>
    );
  }
};

const Menu = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        borderWidth: "0px 0px 2px 0px",
        borderStyle: "solid",
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Sound />
      </Box>

      <MenuItem onClick={props.resetForm}>Home</MenuItem>
      <AboutDialog />
      <SearchDialog
        searchArticles={props.searchArticles}
        handleChange={props.handleChange}
        setFormSubmit={props.setFormSubmit}
        inputs={props.inputs}
        setInputs={props.setInputs}
        resetForm={props.resetForm}
      />
    </Box>
  );
};

const ArticlesTable = (props) => {
  useEffect(() => dayjs.extend(utc), []);

  if (props.isError) {
    return <p>Error. Try again later.</p>;
  } else if (props.articlesListResult != null) {
    if (props.articlesListResult.articleList != null && props.articlesListResult.articleList.length > 0) {
      return props.articlesListResult.articleList.map((article, index) => {
        return (
          <Box key={index} sx={{ p: 2, width: "100%", borderWidth: "0px 0px 2px 0px", borderStyle: "solid" }}>
            <Grid item>
              <Grid container>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "35px" }} variant="h6">
                    {article.title}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{dayjs(article.datetime).local().format("MM/DD/YYYY")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ padding: "10px" }}>{article.textHTML != null ? parse(article.textHTML) : ""}</Typography>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Box component="img" src={article.imageRef} sx={{ maxWidth: "70%" }}></Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        );
      });
    } else return <p>No articles.</p>;
  } else return null;
};

const ArticlesList = (props) => {
  const initialValues = {
    title: "",
    dateFrom: null,
    dateTo: null,
    isDescending: false,
    errors: {},
  };

  const {
    searchArticles,
    changePage,
    resetForm,
    handleChange,
    setFormSubmit,
    isError,
    isLoading,
    inputs,
    setInputs,
    result: articlesListResult,
  } = useSearch(props, initialValues);

  React.useEffect(() => {
    searchArticles();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Logo />
        </Grid>
        <Grid item xs={12}>
          <Menu
            searchArticles={searchArticles}
            handleChange={handleChange}
            resetForm={resetForm}
            setFormSubmit={setFormSubmit}
            inputs={inputs}
            setInputs={setInputs}
          />
        </Grid>
        <Grid item xs={12} sx={{ minHeight: "100vh" }}>
          {/* <Fade in={isLoading} unmountOnExit={true}>
            <p>Loading...</p>
          </Fade> */}

          {isLoading && <p>Loading...</p>}

          {!isLoading && (
            <Fade in={!isLoading}>
              <Grid container>
                <ArticlesTable searchArticles={searchArticles} isError={isError} articlesListResult={articlesListResult} />
              </Grid>
            </Fade>
          )}
        </Grid>
        <Grid item xs={12} sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
          {articlesListResult != null && (
            <Pagination
              count={articlesListResult.pageNumTotal}
              page={articlesListResult.currentPage}
              onChange={changePage}
              defaultPage={1}
              color="primary"
              size="large"
              showFirstButton
              showLastButton
              shape="rounded"
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ArticlesList;
