import { useState } from "react";

const useApi = (setResult, setIsError, setIsLoading) => {
  const getApiBase = () => {
    const { REACT_APP_ENV } = process.env;

    if (REACT_APP_ENV == "Development") {
      return "https://localhost:44339/api/";
    } else if (REACT_APP_ENV == "Test") {
      return "https://localhost:44339/api/";
    } else if (REACT_APP_ENV == "PreProduction") {
      return "https://localhost:44339/api/";
    } else if (REACT_APP_ENV == "Production") {
      return "https://api.blog.alvrineom.com/api/";
    }
  };

  const getHeaders = () => {
    const { REACT_APP_HUSH } = process.env;

    const config = {};
    config.headers = {};
    config.headers["xyCru45dl"] = REACT_APP_HUSH;
    config.headers["Content-Type"] = "application/json";

    return config.headers;
  };

  return {
    fetchArticles: (data) => {
      const requestOptions = {
        method: "POST",
        //headers: { "Content-Type": "application/json" },
        headers: getHeaders(),
        body: JSON.stringify(data),
      };

      fetch(getApiBase() + "article/search-articles", requestOptions)
        .then(async (response) => {
          const responseData = await response.json();

          if (response.ok) {
            setResult(responseData);
            setIsLoading(false);
          } else {
            setIsError(true);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
        });
    },
  };
};

const useSearch = (props, initialForm) => {
  const initialPaging = {
    newPage: 1,
  };

  const [inputs, setInputs] = useState(initialForm);
  const [formSubmit, setFormSubmit] = useState(initialForm);
  const [pageSubmit, setPageSubmit] = useState(initialPaging);
  const [result, setResult] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { fetchArticles } = useApi(setResult, setIsError, setIsLoading);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const searchArticles = (event) => {
    if (event != null) {
      event.preventDefault();

      setFormSubmit(inputs);
      //setFormSubmit({ ...inputs, loading: true});
    }

    const request = {
      FormData: inputs,
      PagingData: pageSubmit,
    };

    setIsLoading(true);
    fetchArticles(request);
  };

  const resetForm = () => {
    const request = {
      FormData: initialForm,
      PagingData: initialPaging,
    };

    setFormSubmit(initialForm);
    setPageSubmit(initialPaging);

    setIsLoading(true);
    fetchArticles(request);
  };

  const changePage = (event, value) => {
    const data = {
      newPage: value,
    };

    setPageSubmit(data);

    const request = {
      FormData: formSubmit,
      PagingData: data,
    };

    fetchArticles(request);
    setIsLoading(true);

    window.scrollTo(0, 0);
  };

  return {
    searchArticles,
    changePage,
    handleChange,
    setFormSubmit,
    result,
    isError,
    inputs,
    setInputs,
    isLoading,
    resetForm,
  };
};

export default useSearch;
