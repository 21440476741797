import "./App.css";
import PageContainer from "./PageContainer";
import { ThemeProvider, createTheme } from "@mui/material";
import { SiteConsts } from "./SiteConsts";
import ErrorBoundary from "./error/ErrorBoundary";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
  },
  typography: {
    allVariants: {
      fontFamily: SiteConsts.FONT_KEY_GLOBAL_1,
    },
  },
});
/////
function App() {
  return (
    <>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <PageContainer />
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
}

export default App;
