import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import { SiteConsts } from "./SiteConsts";

export default function MenuItem(props) {
  return (
    <>
      <Box
        sx={{
          cursor: "pointer",
          fontSize: { xs: "17px", sm: "20px" },
          fontFamily: SiteConsts.FONT_KEY_GLOBAL_1,
          fontWeight: "bold",
          mx: { xs: 0.5, sm: 1, md: 2 },
        }}
        onClick={props.onClick}
      >
        {props.children}
      </Box>
    </>
  );
}
