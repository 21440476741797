const ClientErrorPage = (props) => {
  return (
    <div style={{ padding: "10px" }}>
      <h1>Ooops, something went wrong! </h1>
      <h3>Please try again later. </h3>
    </div>
  );
};

export default ClientErrorPage;
