import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import ArticlesList from "./ArticlesList";
import { Grid } from "@mui/material";

export default function PageContainer() {
  return (
    <>
      <Box sx={{ backgroundColor: "moccasin", minHeight: "100vh" }}>
        <CssBaseline />
        <Container maxWidth="md" sx={{ borderWidth: "0px 6px 0px 6px", borderStyle: "double", minHeight: "100vh" }}>
          {/* <Box sx={{ bgcolor: "#cfe8fc", height: "100vh" }} /> */}
          <ArticlesList />
        </Container>
      </Box>
      <Grid item xs={12} sx={{ backgroundColor: "black", color: "white", p: 2 }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          Copyright @ {new Date().getFullYear()} Alvrineom
        </Box>
      </Grid>
    </>
  );
}
